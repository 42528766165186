
// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API;

export const fetchQRInfoUrl = `${baseUrl}/myLook/fetchQRInfo`;
export const fetchQRResultUrl = `${baseUrl}/myLook/fetchQRResult`;

export const startLogServetUrl = `${baseUrl}/manage/startLogServe`;
export const stopLogServetUrl = `${baseUrl}/manage/stopLogServe`;
export const fetchZipFilesUrl = `${baseUrl}/manage/fetchZipFiles`;
export const fetchZipDataUrl = `${baseUrl}/manage/fetchZipData`;