<template>
  <div class="logs">
    <div class="logs-top">
      <div class="logs-top-one">
        <div class="logs-top-one-first">
          <div class="logs-top-one-title">一、设置</div>
          <el-input class="w250" v-model="ip" placeholder="请输入内容"></el-input>
          <el-button class="w100" @click.prevent="clickWhite">white</el-button>
        </div>
        <div class="logs-top-one-first">
          <div class="logs-top-one-title2">二、在线实时查看</div>
          <el-button class="w100" @click.prevent="clickViewNow">实时预览</el-button>
          <el-button class="w100" @click.prevent="clickStopViewNow">关闭预览</el-button>
        </div>
      </div>
      <div class="logs-top-one marginTop10">
        <div class="logs-top-one-title">三、历史查询</div>
        <el-select class="w250" v-model="value239" placeholder="请选择">
          <el-option
              v-for="item in options239"
              :key="item.name"
              :label="item.name"
              :value="item.name">
          </el-option>
        </el-select>
        <el-button class="w100" @click.prevent="clickSee239">查看239</el-button>

        <el-select class="w250 logs-top-one-select" v-model="value204" placeholder="请选择">
          <el-option
              v-for="item in options204"
              :key="item.name"
              :label="item.name"
              :value="item.name">
          </el-option>
        </el-select>
        <el-button class="w100" @click.prevent="clickSee204">查看204</el-button>
      </div>
    </div>
    <div class="logs-content">
      <el-input
          :rows="row"
          resize="none"
          type="textarea"
          v-model="content"
          :disabled="true">
      </el-input>
    </div>
  </div>
</template>

<script>

  import {startLogServetUrl, fetchZipFilesUrl, stopLogServetUrl} from '../../api';
  import JSZip from 'jszip'

  export default {
    name: "Manage",
    data() {
      return {
        options239: [],
        options204: [],
        value239: '',
        value204: '',
        content: '',
        row: 0,
        ip: '',
      }
    },
    mounted() {
      this.fetchOptions()
      this.row = parseInt((document.documentElement.clientHeight - 120) / 21)
    },
    methods: {
      async clickViewNow() {
        const loadingInstance1 = this.$loading({
          background: 'rgba(0, 0, 0, 0.7)',
        });
        try {
          let a = await this.fetch239(startLogServetUrl)
          let b = await this.fetch204(startLogServetUrl)
          loadingInstance1.close()
          if (a.code == 1 && b.code == 1) {
            window.location.href = "http://log.howiech.com"
          }
        } catch (e) {
          console.log(e)
          loadingInstance1.close()
        }
      },
      async clickStopViewNow() {
        const loadingInstance1 = this.$loading({
          background: 'rgba(0, 0, 0, 0.7)',
        });
        try {
          await this.fetch239(stopLogServetUrl)
          await this.fetch204(stopLogServetUrl)
          loadingInstance1.close()
        } catch (e) {
          console.log(e)
          loadingInstance1.close()
        }
      },
      clickSee239() {
        var url239 = ''
        this.options239.forEach(item => {
          if (item.name == this.value239) {
            url239 = item.url
          }
        })

        this.handleZipLogToSee(url239, this.value239)
      },
      clickSee204() {
        var url204 = ''
        this.options204.forEach(item => {
          if (item.name == this.value204) {
            url204 = item.url
          }
        })

        this.handleZipLogToSee(url204, this.value204)
      },
      handleZipLogToSee(url, name) {
        const loadingInstance1 = this.$loading({
          background: 'rgba(0, 0, 0, 0.7)',
        });
        var self = this
        var req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.responseType = "blob";
        req.onload = function () {
          var blob = req.response;
          var f = new File([blob], name)
          if (/zip$/.test(blob.type)) {
            var newZip = new JSZip();
            newZip.loadAsync(f).then(zip => {
              zip.file(Object.keys(zip.files)[0]).async('string').then(data => {
                // 显示
                loadingInstance1.close()
                self.content = data
              })
            })
          } else {
            console.log('22')
            var reader = new FileReader()
            reader.readAsText(f, 'utf8')
            reader.onload = () => {
              loadingInstance1.close()
              self.content = reader.result
            }
          }
        }
        req.onerror = () => {
          loadingInstance1.close()
        }
        req.send();
      },
      clickWhite() {
        if (this.$utils.isEmpty(this.ip)) {
          this.$message('请输入关键信息');
          return
        }
        const loadingInstance1 = this.$loading({
          background: 'rgba(0, 0, 0, 0.7)',
        });
        Promise.all([this.fetchSetSuperWhite('http://101.32.185.239:8887'),
          this.fetchSetSuperWhite('http://101.32.76.204:8887'),
          this.fetchSetSuperWhite('http://129.226.15.79:8887'),
          this.fetchSetSshWhite('http://101.32.185.239:8887'),
          this.fetchSetSshWhite('http://101.32.76.204:8887'),
          this.fetchSetSshWhite('http://129.226.15.79:8887')])
            .then((result) => {
              console.log(result)
              loadingInstance1.close()
            }).catch((error) => {
              console.log(error)
              loadingInstance1.close()
            });

      },
      async fetch239(url) {
        let a = await this.$axios.get(url + "?ipNum=239")
        console.log(a.data)
        return a.data;
      },
      async fetch204(url) {
        let a = await this.$axios.get(url + "?ipNum=204")
        console.log(a.data)
        return a.data;
      },
      async fetchOptions() {
        const loadingInstance1 = this.$loading({
          background: 'rgba(0, 0, 0, 0.7)',
        });
        let a = await this.fetch239(fetchZipFilesUrl)
        let b = await this.fetch204(fetchZipFilesUrl)

        this.options239 = []
        a.data.forEach((item) => {
          this.options239.push({
            url: 'http://101.32.185.239:8887' + item,
            name: item
          })
        })
        this.options204 = []
        b.data.forEach((item) => {
          this.options204.push({
            url: 'http://101.32.76.204:8887' + item,
            name: item
          })
        })

        loadingInstance1.close()
      },
      fetchSetSuperWhite(url) {
        return new Promise((resolve, reject) => {
          var req = new XMLHttpRequest();
          req.open("GET", url + '/setSuperWhite?ip=' + this.ip, true);
          req.responseType = "json";
          req.onload = function () {
            resolve(req.response)
          }
          req.onerror = function () {
            reject()
          }
          req.send();
        })

        // return this.$axios.get(url + '/setSuperWhite?ip=' + this.ip)
      },
      fetchSetSshWhite(url) {
        return new Promise((resolve, reject) => {
          var req = new XMLHttpRequest();
          req.open("GET", url + '/setSshWhite?ip=' + this.ip, true);
          req.responseType = "json";
          req.onload = function () {
            resolve(req.response)
          }
          req.onerror = function () {
            reject()
          }
          req.send();
        })
        // axios 跨域拦截了，但 xmlhttprequest 可以请求
        // return this.$axios.get(url + '/setSshWhite?ip=' + this.ip)
      }
    }
  }
</script>

<style scoped>

  @import "../../ui/ui.element.reset.css";

  .logs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .logs-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
  }

  .logs-top-one {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 40px;
    font-size: 15px;
    font-weight: bolder;
  }


  .logs-top-one-first {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }


  .logs-top-one-title {
    width: 110px;
    height: 40px;
    line-height: 40px;
  }

  .logs-top-one-title2 {
    margin-left: 20px;
    width: 140px;
    height: 40px;
    line-height: 40px;
  }

  .logs-top-one-select {
    margin-left: 20px;
  }

  .marginTop10 {
    margin-top: 10px;
  }

  .w250 {
    width: 250px;
  }

  .w100 {
    width: 100px;
  }

  .logs-content {
    margin-top: 10px;
    width: 100%;
  }

</style>